<template>
  <b-card
    :title="'Edit '+ details.name"
  >
    <b-form @submit.prevent="saveChanges">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Name"
            label-for="mc-name"
          >
            <b-form-input
              id="mc-name"
              v-model="details.name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="mc-Description"
          >
            <b-form-textarea
              id="mc-description"
              v-model="details.description"
              placeholder="Description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save Changes
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      category: {},
      details: {
        name: '',
        description: '',
      },
      loading: false,
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.$http.get(`${this.$url}/category/${router.currentRoute.params.id}`)
        .then(response => {
          if (response.data.status) {
            this.category = response.data.data
            this.details.name = this.category.name
            this.details.description = this.category.description
          }
        })
    },
    saveChanges() {
      this.loading = true
      this.$http.put(`${this.$url}/category/${router.currentRoute.params.id}`, this.details)
        .then(response => {
          if (response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              window.location.reload()
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
